.container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0;
}

.dropdownHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdownHeader.active {
  background-color: #20252f;
}

.activeSvg {
  stroke: white;
}

.dropdownHeader > p {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.96px;
}

.dropdownHeader.active > p {
  color: white;
}
.dropdownHeader > div > svg {
  height: 40px;
  width: 40px;
}

.dropdownContainer {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .dropdownHeader > p {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.72px;
  }

  .dropdownContainer {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .dropdownHeader > div > svg {
    height: 36px;
    width: 36px;
  }
}

@media screen and (max-width: 767px) {
  .dropdownHeader > p {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.72px;
  }

  .dropdownContainer {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .dropdownHeader > div > svg {
    height: 36px;
    width: 36px;
  }
}
