.container {
  padding: 100px 3rem;
}

.header {
  text-align: center;
  margin-bottom: 2rem;
}

.header > h4 {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
  width: 495px;
  margin: 1rem auto;
}

.header > p {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
}

.aboutUserType {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutUserType > div {
  width: 487px;
  height: 380px;
  border: 1px solid #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem;
  text-align: center;
}

.aboutUserType > div > img {
  width: 109px;
  height: 30px;
  margin-bottom: 1rem;
}

.aboutUserType > div > h4 {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.aboutUserType > div > p {
  color: var(--dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 1.5rem;
}

.aboutUserType > div > div > button {
  color: var(--dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  background: transparent;
  border:0;
}

.aboutUserType > div > div > svg:nth-child(3) {
  display: block;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    text-align: center;
    padding: 3rem 1.5rem;
  }

  .aboutContainer {
    margin-bottom: 2rem;
  }

  .header > h4 {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.14px;
    width: 70%;
  }

  .header > p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    width: 80%;
    margin: 1em auto;
  }

  .aboutUserType {
    flex-wrap: wrap;
    gap:1rem;
  }

  .aboutUserType > div > h4 {
    font-size: 18px;
    line-height: 32px;
  }

  .aboutUserType > div > p {
    font-size: 16px;
    line-height: 24px;
    width: 70%;
    margin-bottom: 1rem;
  }

  .aboutUserType > div > div > a {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    text-align: center;
    padding: 3rem 1.5rem;
  }

  .aboutContainer {
    margin-bottom: 2rem;
  }

  .aboutUserType > div {
    width: 326px;
  }

  .header > h4 {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.14px;
    width: 100%;
  }

  .header > p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    width: 80%;
    margin: 1em auto;
  }

  .aboutUserType {
    flex-wrap: wrap;
    gap:1rem;
  }

  .aboutUserType > div {
    padding: 1rem;
  }

  .aboutUserType > div > h4 {
    font-size: 18px;
    line-height: 32px;
  }

  .aboutUserType > div > p {
    font-size: 16px;
    line-height: 24px;
    width: 70%;
    margin-bottom: 1rem;
  }

  .aboutUserType > div > div > a {
    font-size: 16px;
    line-height: 24px;
  }
}
