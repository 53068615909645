.container {
  padding: 4rem 100px;
  background: #037ef3;
  overflow-x: hidden;
}

.isComponentContainer {
  background: #fff;
}

.header > h4 {
  color: var(--White, #fff);
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
  text-align: center;
}

.aboutText {
  font-style: italic;
  font-weight: 250;
}

.isComponentContainer > .header > h4 {
  color: var(--Dark-Main, #20252f);
}
.isComponentContainer > .header > p {
  color: var(--Dark-sub, #1d2129);
}

.header > p {
  color: var(--White, #fff);
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
}

.ourLCSection {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.LC {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.5px);
  padding: 1rem;
  flex-basis: 23%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.isComponentContainer > .ourLCSection > .LC {
  background: #20252f;
  color: var(--White, #fff);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .header > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .header > p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .LC {
    flex-basis: 46%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .header > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .header > p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .LC {
    flex-basis: 95%;
  }
}
