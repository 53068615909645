#aboutAiesec {
    background: url('../../Assets/Images/aboutA.jpg'), lightgray 50% / cover no-repeat;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    @media screen and (max-width: 500px) {
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: 500px auto;
    }
  
    .bgGradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.56;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), 
                  linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), 
                  linear-gradient(90deg, #7949E8 -8.05%, #3D8DFC 110.58%);
    }
  
    .darkGradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background: linear-gradient(90deg, #000 35.57%, rgba(0, 0, 0, 0) 110.58%);
      @media screen and (max-width: 500px) {
        background: linear-gradient(to bottom, #000 35.57%, rgba(0, 0, 0, 0) 110.58%);
      }
    }
  
    .backGradient {
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      /* background: url('../../Assets/Images/back-about.png'), lightgray -111.411px -54.537px / 113.947% 117.261% no-repeat; */
      mix-blend-mode: overlay;
      background-size: 1000px auto;
      background-position: center right;
    }
  }

.fcd{
  font-family: 'Clash Display', sans-serif;
}