.container {
  min-height: 90vh;
}

.container > h4 {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 300;
  line-height: 64px;
  padding: 8vh 92px 0 92px;
  margin: 1em 0;
}

.container > h4 > span {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 700;
  line-height: 64px;
}

.inputSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 92px 0 92px;
  margin-bottom: 4rem;
}

.inputSection > div:nth-child(1) {
  width: 498px;
  position: relative;
}

.inputSection > div:nth-child(1) > input {
  width: 100%;
  padding: 1rem;
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.18px;
  outline: none;
  border: none;
  border-bottom: 1px solid #a0a0a0;
}

.inputSection > div:nth-child(1) > svg {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* .inputSection > div:nth-child(1) {
  width: 76px;
  height: 76px;
  flex-shrink: 0;
  background-color: var(--white, #fff);
  box-shadow: 0px 4px 24px rgba(171, 171, 171, 0.4);
  border-radius: 50%;
  margin-left: auto;
} */

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;

  }

  .container > h4 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    padding: 8vh 24px 0 24px;
    margin: 1em 0;
  }

  .container > h4 > span {
    font-size: 32px;
  }

  .inputSection {
    padding: 0 24px 0 24px;
    margin-bottom: 4rem;
  }
  .inputSection > div:nth-child(1) {
    width: 100%;
    position: relative;
  }

  .inputSection > div:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
    min-height: 74vh;
  }

  .container > h4 {
    font-size: 32px;
    font-weight: 300;
    line-height: 40px;
    padding: 8vh 24px 0 24px;
    margin: 1em 0;
  }

  .container > h4 > span {
    font-size: 32px;
    line-height: 40px;
  }

  .inputSection {
    padding: 0 24px 0 24px;
    margin-bottom: 7rem;
  }
  .inputSection > div:nth-child(1) {
    width: 100%;
    position: relative;
  }

  .inputSection > div:nth-child(1) > input {
    width: 100%;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    padding: 0 2rem 0 0;
  }

  .inputSection > div:nth-child(2) {
    display: none;
  }
}
