.container {
  padding: 4rem 100px;
  background: var(--Light-color, #f0f0f0);
  overflow: hidden;
}

.header {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
  width: 409px;
}

.header > span {
  position: relative;
}

.header > span > svg {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 162px;
}

.ebInfoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.ebPerson {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 266px;
  height: 258px;
  flex-shrink: 0;
}

.ebPerson > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.ebPerson > div:nth-child(2) {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.5px);
  padding: 1rem 1.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .header {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .header {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 100%;
  }
}
