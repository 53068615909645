/* Drawer Stylings */

.drawer {
  padding: 2rem;
  /* width: 50% !important; */
}

.drawerRoot>div:nth-child(3) {
  width: 40% !important;
}

.drawer>div:nth-child(1) {
  border-bottom: none;
}

.drawer>div:nth-child(1)>div:nth-child(1) {
  flex-direction: row-reverse;
  align-items: flex-start;

}

.drawer>div:nth-child(1) button svg {
  color: #2E3A59;
  background-color: #f0f0f0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.drawer>div:nth-child(1) button>span {
  color: #2E3A59;
  height: 42px;
  background-color: #f0f0f0;
  width: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.drawerTitle h4 {
  font-size: 2rem;
  font-style: italic;
  line-height: 32px;
  font-family: 'Lato', sans-serif;
  color: var(--Dark-Main, #20252f);
  font-weight: 700;
  margin-bottom: 0;
  padding-top: 2.5rem;
}

.drawerTitle p {
  font-size: 1rem;
  font-style: italic;
  line-height: 28px;
  font-family: 'Lato', sans-serif;
  margin-top: 0.5rem;
  color: var(--Dark-Main, #20252f);
  font-weight: 300;
  margin-bottom: 0;
}

.drawerForm input {
  border-radius: 5px;
  border: 1px solid var(--border, #dcdcdc);
  color: var(--Dark-Main, #20252f);
  font-style: italic;
  font-weight: 300;
  padding: 0.75rem;
  font-family: 'Lato', sans-serif;
}

.successful{
  height:100%;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:1rem;
  flex-direction:column;
}

.successful > div{
  text-align:center;
}

.successful > div > h4{
  font-size: 2rem;
  font-style: italic;
  line-height: 32px;
  font-family: 'Lato', sans-serif;
  color: var(--Dark-Main, #20252f);
  font-weight: 700;
}

.successful > div > p{
  font-size: 1rem;
  font-style: italic;
  line-height: 32px;
  width: 70%; 
  line-height: 28px;
  margin: 1rem auto;
  font-family: 'Lato', sans-serif;
  color: var(--Dark-Main, #20252f);
  font-weight: 300;
}

.drawerForm input::placeholder {
  color: var(--Dark-Main, #20252f);
  font-style: italic;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
}

.drawerForm {
  padding-top: 2rem;
  border-top: 1px solid var(--border, #dcdcdc);
}

.drawerFormBtn {
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;
  border-top: 1px solid var(--border, #dcdcdc);
}

.drawerFormBtn button {
  padding: 0.75rem 2rem;
  border-radius: 5px;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  border: none;
/* 
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.35);
  background: #f0f0f0; */
}

.drawerFormLabel > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) > div {
 
  font-size: 12px;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 500;
}

.drawerFormLabel>div>div>label {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 28px;
  font-family: 'Lato', sans-serif;
  color: var(--Dark-Main, #20252f);
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.drawerFormSelect {
  height: 48px;
}

.drawerFormSelect>div:nth-child(1) {
  width: 100%;
  height: auto;
  padding: 1rem 0.75rem;
  border-radius: 5px;
  border: 1px solid var(--border, #dcdcdc);
  color: var(--Dark-Main, #20252f);
  font-style: italic;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
}


@media screen and (min-width: 768px) and (max-width: 1167px) {
  .drawerRoot>div:nth-child(3) {
    width: 50% !important;
  }
}

@media screen and (max-width: 767px) {
  .drawer {
    padding: 1rem;
  }


  .successful > div > p{
    font-size: 1rem;
    width: auto; 
  }
  .drawerRoot>div:nth-child(3) {
    width: 94% !important;
  }
}