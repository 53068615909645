.container {
  padding: 1rem 0px 1rem 100px;
  width: 70%;
}

.container > h4 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.projectsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.project {
  flex-basis: 30%;
}

.project > img {
  width: 264px;
  height: 263px;
  border: 1px solid #dcdcdc;
  object-fit: contain;
}

.project > h6 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-top: 1rem;
}

.project > span {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.16px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem 1.5rem;
    width: 100%;
  }

  .projectsContainer {
    flex-direction: column;
  }

  .project {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem 1.5rem;
    width: 100%;
  }

  .projectsContainer {
    flex-direction: column;
  }

  .project {
    flex-basis: 100%;
  }
}
