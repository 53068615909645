.container > h4 {
  font-family: Lato;
  font-size: 72px;
  font-weight: 600;
  line-height: 69.12px;
  color: #2d2d2d;
}

.container > p {
  font-family: Raleway;
  font-size: 24px;
  font-weight: 300;
  line-height: 23.04px;
  color: #808080;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > h4 {
    font-size: 20px;
    line-height: 130.5%;
  }

  .container > p {
    font-size: 16px;
    font-weight: 275;
    line-height: 140%;
  }
}

@media screen and (max-width: 767px) {
  .container > h4 {
    font-size: 20px;
    line-height: 130.5%;
  }

  .container > p {
    font-size: 16px;
    font-weight: 275;
    line-height: 140%;
  }
}
