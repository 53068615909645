
.container {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  /* background-image: url("../../Assets/Images/organizationHeroBg.svg"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.container > .imgbg:nth-child(1){
  position: absolute;
  bottom:0;
  left: 38%;
}

.container > .imgbg:nth-child(2){
  position: absolute;
  bottom: 10rem;
  left: 160px;
}

.container > .imgbg:nth-child(3){
  position: absolute;
  bottom: 9rem;
  right: 160px;
}

.container > .imgbg:nth-child(4){
  position: absolute;
  top: 5rem;
  right: 9rem;
}

.container > .imgbg:nth-child(5){
  position: absolute;
  top:3rem;
}

.container > .imgbg:nth-child(6){
  position: absolute;
  top: 5rem;
  left: 10rem;
}

.container > h4 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 300;
  line-height: 64px;
  width: 843px;
  text-align: center;
}

.container > h4 > span {
  position: relative;
}

.container > h4 > span > span:nth-child(1) {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 700;
  line-height: 64px;
}

.container > h4 > span > svg:nth-child(2) {
  position: absolute;
  left: -20px;
  right: -20px;
  margin: auto;
  bottom: -8px;
  width: 165px;
}

.container > p:nth-child(2) {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 46.351px;
  width: 859px;
  margin: 0 auto;
}

.container > p:nth-child(4) {
  color: #bbb;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 217.5px;
}
.container > div {
  width: 178px;
  margin-top: 2rem;
}

.container > div > button > a {
  color: #fff;
  text-decoration: none;
}

/* .container > .imgbg:nth-child(1) {
  position: absolute;
  bottom: 0;
  left: 40%;
}

.container > .imgbg:nth-child(2) {
  position: absolute;
  bottom: 20%;
  left: 160px;
}

.container > .imgbg:nth-child(3) {
  position: absolute;
  bottom: 20%;
  right: 160px;
}

.container > .imgbg:nth-child(4) {
  position: absolute;
  top: 10%;
  right: 160px;
}

.container > .imgbg:nth-child(5) {
  position: absolute;
  top: 20%;
}

.container > .imgbg:nth-child(6) {
  position: absolute;
  top: 20%;
  left: 160px;
} */

.container > img {
  z-index: -1;
}


@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > h4,
  .container > h4 > span > span:nth-child(1) {
    color: var(--Dark-Main, #20252f);
    font-size: 32px;
    line-height: 40px;
  }

  .container > h4 {
    width: 70%;
  }

  .container > .imgbg:nth-child(1) {
    position: absolute;
    bottom: 2rem;
    left: 17rem;
  }

  .container > .imgbg:nth-child(2) {
    position: absolute;
    bottom:10rem;
    left: 2rem;
  }

  .container > .imgbg:nth-child(3) {
    position: absolute;
    bottom: 11rem;
    right: 27px;
  }

  .container > .imgbg:nth-child(4) {
    position: absolute;
    top: 3rem;
    right: 27px;
  }

  .container > .imgbg:nth-child(5) {
    position: absolute;
    top: 6rem;
  }

  .container > .imgbg:nth-child(6) {
    position: absolute;
    top: 3rem;
    left: 27px;
  }

  .container > p:nth-child(2) {
    font-size: 18px;
    line-height: 32px;
    width: 80%;
  }

  .container > p:nth-child(4) {
    font-size: 12px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1024px) and (orientation: landscape){
  .container > .imgbg:nth-child(1) {
    display:none;
  }

  .container > .imgbg:nth-child(2) {
    position: absolute;
    bottom: 0rem;
    left: 2rem;
  }

  .container > .imgbg:nth-child(3) {
    position: absolute;
    bottom: 3rem;
    right: 27px;
  }

  .container > .imgbg:nth-child(4) {
    position: absolute;
    top: -1rem;
    right: 27px;
  }

  .container > .imgbg:nth-child(5) {
    position: absolute;
    top: 8rem;
    left: 13rem;
  }

  .container > .imgbg:nth-child(6) {
    position: absolute;
    top: -1rem;
    left: 27px;
  }

}

@media screen and (max-width: 767px) {
  .container > h4,
  .container > h4 > span > span:nth-child(1) {
    color: var(--Dark-Main, #20252f);
    font-size: 32px;
    line-height: 40px;
  }

  .container > h4 {
    width: 70%;
  }

  .container > div > img{
    width: 100%;
    height: 100%;
  }

  .container > h4 > span:nth-of-type(2) > svg:nth-child(2) {
    display: none;
  }

  .container > .imgbg:nth-child(1) {
    position: absolute;
    bottom: 0;
    left: 40%;
    display: none;
  }

  .container > .imgbg:nth-child(2) {
    position: absolute;
    bottom: 9rem;
    left: 7px;
    width: 72px;
    height: 72px;
  }

  .container > .imgbg:nth-child(3) {
    position: absolute;
    bottom: 11rem;
    right: 3rem;
    width: 67px;
    height: 67px;
  }

  .container > .imgbg:nth-child(4) {
    position: absolute;
    top: 0;
    right: 7px;
    width: 62px;
    height: 62px;
  }

  .container > .imgbg:nth-child(5) {
    position: absolute;
    top: 8rem;
    width: 33px;
    height: 33px;
    left: 12rem;
  }

  .container > .imgbg:nth-child(6) {
    position: absolute;
    top: 4rem;
    left: 7px;
    width: 55px;
    height: 55px;
  }

  .container > p:nth-child(2) {
    font-size: 18px;
    line-height: 32px;
    width: 80%;
  }

  .container > p:nth-child(4) {
    font-size: 12px;
  }
}
