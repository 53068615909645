.testimonialContainer {
  background: url(../../Assets/Images/testimonialBackground.svg) no-repeat
    center;
  background-size: cover;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0) 50%
    ),
    url(../../Assets/Images/testimonialBackground.svg);
  padding: 60px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-left: 10px solid #037ef3;
  height: 602px;
  user-select: none;
  overflow: hidden;
}

.testimonialContent {
  max-width: 75%;
  color: #fff;
  font-family: Lato, sans-serif;
}

.testimonialText {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.4px;
  margin-bottom: 2rem;
  width: 51%;
}

.testimonialAuthor {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #d2cdcd;
}

.testimonialAuthor span {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.16px;
}

.testimonialAuthor .dot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonialNavButtons {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.navArrow {
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
  color: #037ef3;
  border: none;
  outline: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navArrow:hover {
  background-color: #fff;
  color: #037ef3;
  transform: scale(1.1);
}

.disabled {
  color: #e2e2e2;
  cursor: not-allowed;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.testimonialContainer {
  animation: slide-in 0.5s ease-in-out;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .testimonialText {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .testimonialContainer {
    padding: 40px 24px;
    height: 500px;
  }

  .testimonialContent {
    max-width: 100%;
  }

  .testimonialText {
    font-size: 20px;
    line-height: 24px;
    width: 100%;
  }

  .testimonialAuthor span {
    font-size: 14px;
  }
}
