.container {
    min-height: calc(100vh - 10vh);
    background: url('../../Assets/Images/wavy.png');
    background-color: #FCEFEF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logoContainer {
    padding: 20px 0px 40px 40px;
}

.heroSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heroTextSection {
    padding-left: 100px;
}

.heroTextSection > h4{
    font-family: Regular;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 1rem;
    width: 50%;
    text-transform: capitalize;
    text-align: left;
}

.heroTextSection > p {
    font-style: italic;
    font-size: 20px;
    width: 50%;
    font-weight: 300;
    margin-bottom: 2rem;
}

.heroTextSection > div > button {
    width: 200px;
    background-color: transparent;
    border: 2px solid #000;
    color: #000;
    border-radius: 12px;
    box-shadow: 4px 4px 0px 0px #008F4F;
    padding: 0.5rem 1rem;
}

.colorTower {
    display: none;
}

.heroImage{
    margin-top: -190px;
}

.aboutTextSection > h4 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 28px;
    font-family: Regular;
    margin-bottom: 1rem;
}

.aboutTextSection > p {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    width: 50%;
}

.highLight {
    color: #008F4F;
    font-weight: 500;
}

.aboutSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px;
}

.whySection {
    background-color: #6B205E;
    border-radius: 20px;
    padding: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.whySection > h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 3rem;
    font-family: Regular;
    font-weight: 600;
}

.whySection > p {
    font-style: italic;
    font-weight: 300;
    color: #fff;
    display: block;
    text-align: center;
    width: 48%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.whyImageSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.partOne {
    display: flex;
    align-items: center;
}

.partOne > div {
    background-color: #FCEFEF;
    padding: 2px 2px 40px;
    width: 150px;
}

.partTwo {
    background-color: #FCEFEF;
    padding: 2px 2px 40px;
    width: 150px;
}

.partOne > div:nth-child(2) {
    transform: rotate(10deg);
    margin-left: -30px;
    z-index: 1;
}

.partOne > div:nth-child(1) {
    z-index: 10;
}

.waitListSection {
    padding: 100px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.waitListSection > h4 {
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    font-family: Regular;
    font-size: 24px;
    margin-bottom: 1rem;
}

.waitListSection > p {
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 2rem;
    width: 52%;
    text-align: center;
}

.ourLCSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}
  
.LC {
    border-radius: 8px;
    background: #FFDE00;
    padding: 1rem;
    flex-basis: 20%;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .logoContainer {
        padding: 20px 0px 20px 20px;
    }

    .logoContainer > img{
        width: 100px;
    }

    .heroSection{
        display: block;
    }

    .heroTextSection {
        padding: 20px;
        margin-bottom: 3rem;
    }
    
    .heroTextSection > h4{
        font-size: 32px;
        width: 100%;
        text-align: center;
    }
    
    .heroTextSection > p {
        font-size: 18px;
        width: 100%;
    }
    
    .heroTextSection > div {
        display: block;
        text-align: center;
    }
    
    .colorTower {
        display: block;
    }

    .colorblock {
        display: none;
    }

    .heroImage{
        margin-top: 0px;
    }

    .aboutTextSection > h4 {
        font-size: 24px;
        text-align: center;
    }
    
    .aboutTextSection > p {
        width: 100%;
        font-size: 18px;
    }
    
    .aboutSection {
        display: block;
        padding: 100px 20px;
    }

    .whySection > p {
        text-align: left;
        width: 100%;
        font-size: 18px;
    }
    
    .whyImageSection {
        gap: 20px;
    }

    .whySection {
        border-radius: 12px;
        padding: 40px 20px;
    }

    .partOne > div {
        width: 120px;
    }
    
    .partTwo {
        width: 120px;
    }

    .waitListSection > p {
        font-size: 18px;
        width: 100%;
        text-align: left;
    }
    
    .LC {
        flex-basis: 46%;
    }
}