.outerContainer {
  position: relative;
  padding: 72px 100px;
  background: var(--Light-color, #f0f0f0);
  margin-bottom: 220px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}

.header>div:nth-child(1)>h4 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
}

.header>div:nth-child(1)>p {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
}

.header>a:nth-child(2) {
  width: 222px;
  display: block;
}

.header>div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 390px;
  gap: 0.5rem;
}

.header>div:nth-child(2) a {
  text-decoration: none;
  color: #fff;
  width: 50%;
}

.header>div:nth-child(2) a:nth-child(1) {
  text-decoration: none;
  position: relative;
  color: #20252F;
  width: 50%;
  font-size: 1rem;
  font-weight: 700;
}

.header>div:nth-child(2) a:nth-child(1)>svg:nth-of-type(1) {
  position: absolute;
  right: 18px;
  top: 5px;
}

.header>div:nth-child(2) a:nth-child(1)>svg:nth-of-type(2) {
  position: absolute;
  bottom: -12px;
  left: 28px;
}

.partnerSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 26px;
  position: absolute;
  bottom: -50%;
  overflow-x: scroll;
  width: calc(100vw - 206px);
}

.partnerSection::-webkit-scrollbar {
  display: none;
}

.partnerSection {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.partner {
  width: 166px;
  height: 168px;
  border: 1px solid var(--border, #dcdcdc);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 166px;
  flex-shrink: 0;
}

.partner>img {
  max-width: 81.7px;
  max-height: 81.7px;
  flex-shrink: 0;
}

/* Carousel Stylings */

.partnerSectionCarousel {
  --slide-spacing: 0.5rem;
  --slide-size: 50%;
  --slide-height: 19rem;
}

.partnerSectionCarousel__viewport {
  overflow: hidden;
}

.partnerSectionCarousel__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.partnerSectionCarousel__slide {
  flex: 0 0 14%;
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}



@media screen and (min-width: 767px) and (max-width: 1023px) {
  .outerContainer {
    position: relative;
    padding: 26px 56px;
    margin-bottom: 220px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  .header>div:nth-child(1)>h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .header>div:nth-child(1)>p {
    font-size: 18px;
    line-height: 28px;
  }

  .partnerSection {
    justify-content: center;
    gap: 8px;
    position: static;
    overflow-x: hidden;
    width: calc(90vw);

    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .outerContainer {
    position: relative;
    padding: 56px 26px;
    margin-bottom: 40px;
  }

  .header {
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
  }

  .header>div:nth-child(1)>h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .header>div:nth-child(1)>p {
    font-size: 18px;
    line-height: 28px;
  }

  .header>div:nth-child(2){
    width: 178px;
  }

  .partnerSection {
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: static;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
}