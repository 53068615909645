.container {
    min-height: calc(100vh - 10vh);
    background-image: url('../../Assets/Images/spiral.svg');
    background-color: #FCEFEF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutContainer {
    padding: 150px 50px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aboutText > h4 {
    color: #000000;
    font-family: Regular;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
}

.aboutText > p {
    color: #000000;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    width: 50%;
}

.aboutText > p > span{
    font-weight: bold;
}

.aboutImage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.displayContainer {
    padding: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.focusContainer{
    padding: 200px 0px;
}

.focusTextSection {
    display: block;
    text-align: center;
}

.focusTextSection > h4 {
    font-size: 32px;
    font-family: Regular;
    font-weight: bold;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 1rem;
}

.focusTextSection > p {
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.focusTextSection > p > span{
    color:#008F4F ;
    font-weight: 500;
}

.focusImageSection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media screen and (max-width: 767px) {
    .aboutContainer {
        padding: 50px 30px 0px;
        display: block;
    }

    .aboutText > h4 {
        font-weight: 600;
        font-size: 28px;
        text-align: center;
    }
    
    .aboutText > p {
        font-size: 16px;
        width: 100%;
    }

    .aboutText {
        margin-bottom: 2rem;
    }

    .displayContainer {
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .focusContainer{
        padding: 50px 0px;
    }
    
    
    .focusTextSection > h4 {
        font-size: 28px;
    }
    
    .focusTextSection > p {
        font-size: 16px;
        width: 90%;
    }

    .focusImageSection {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
       
}




