.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 82px;
  padding: 4rem 0;
  background: #037ef3;
}

.container > h4 {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
  width: calc(30%);
}

.container > div:nth-child(2) {
  width: calc(50% - 4rem);
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #fff;
  position: relative;
}

.container > div:nth-child(2) > input {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  flex: 1;
  background: #037ef3;
  border: none;
  outline: none;
}

.container > div:nth-child(2) > input::placeholder {
  color: var(--white, #fff);
}

.container > div:nth-child(2) > button {
  border: none;
  outline: none;
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
  background-color: transparent;
  cursor: pointer;
}

.container > div:nth-child(2) > button:disabled {
  /* pointer-events: none; */
  cursor: not-allowed;
}

.warning {
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  position: absolute;
  left: 0;
  bottom: -30px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    gap: 48px;
    padding: 3rem 1.5rem;
    flex-direction: column;
    background: #037ef3;
  }
  .container > h4 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    width: 100%;
  }

  .container > div:nth-child(2) {
    width: calc(50% - 4rem);
    padding: 0.5rem 0;
    border-bottom: 1px solid #fff;
    width: 100%;
  }

  .container > div:nth-child(2) > input {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }

  .container > div:nth-child(2) > button {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    gap: 48px;
    padding: 3rem 1.5rem;
    flex-direction: column;
    background: #037ef3;
  }
  .container > h4 {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    width: 100%;
  }

  .container > div:nth-child(2) {
    width: calc(50% - 4rem);
    padding: 0.5rem 0;
    border-bottom: 1px solid #fff;
    width: 100%;
  }

  .container > div:nth-child(2) > input {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }

  .container > div:nth-child(2) > button {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }
}
