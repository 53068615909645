.container {
  /* height: 90vh; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 98px;
  overflow-x: hidden;
}

.header {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 300;
  line-height: 64px;
  display:block;
  margin-top:10rem;
  height:26vh;
}

.header > span {
  position: relative;
}

.header > span > span:nth-child(1) {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 700;
  line-height: 64px;
}

.header > span > svg:nth-child(2) {
  position: absolute;
  left: -20px;
  right: -20px;
  margin: auto;
  bottom: 0;
  width: 165px;
}

.imageSetion {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.imageSetion > img {
  flex-basis: 20%;
  object-fit: cover;
  height: 350px;
}

.imageSetion > img:nth-child(1) {
  margin-top: 4rem;
}

.imageSetion > img:nth-child(3) {
  margin-top: 10rem;
}

.imageSetion > img:nth-child(4) {
  margin-top: 3rem;
}

.imageSetion > img:nth-child(5) {
  margin-bottom: 7rem;
}

/* Carousel Stylings */

.imageSectionMobile{
  align-items: center;
    justify-content: center;
    gap: 8px;
    position: static;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    display:none;
}

.imageSectionMobileCarousel {
  --slide-spacing: 0.5rem;
  --slide-size: 50%;
  --slide-height: 19rem;
}

.imageSectionMobileCarousel__viewport {
  overflow: hidden;
}

.imageSectionMobileCarousel__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}

.imageSectionMobileCarousel__slide {
  flex: 0 0 25%;
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.partner {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (min-width: 767px) and (max-width: 1300px){
  .container {
    height: unset;
    justify-content: center;
    flex-direction: column;
    gap: 0;
  }

  .header {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-top: 9rem;
  }
}


@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;
    justify-content: center;
    flex-direction: column;
    gap: 0;
  }

  .header {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-top: 6rem;
  }

  .header > span > span:nth-child(1) {
    font-family: Lato;
    font-size: 32px;
    line-height: 40px;
  }

  .header > span > svg:nth-child(2) {
    width: 95px;
  }

  .imageSetion {
    align-items: center;
    justify-content: center;
  }

  .imageSetion > img {
    height: 252px;
  }

  .imageSetion > img:first-child,
  .imageSetion > img:last-child {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
    justify-content: center;
    flex-direction: column;
    gap: 48px;
    padding: 2rem 0;
  }

  .imageSectionMobile{
    display: flex;
  }
  .imageSetion{
    display: none;
  }
  .header {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-top: 3rem;
  }

  .header > span > span:nth-child(1) {
    font-family: Lato;
    font-size: 32px;
    line-height: 40px;
  }

  .header > span > svg:nth-child(2) {
    width: 95px;
  }

  .imageSetion {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .imageSetion > img {
    height: 252px;
  }

  .imageSetion > img:nth-child(3) {
    margin-top: 2rem;
  }

  .imageSetion > img:first-child,
  .imageSetion > img:last-child {
    display: none;
  }
}
