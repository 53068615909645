.container {
  padding: 76px 128px;
  background: #ecf5fc;
}

.container > button {
  width: 200px;
  margin: 3rem auto 0px;
}

.whySection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 96px;
  gap: 1rem;
}

.whySection > div {
  flex-basis: 48%;
  min-width: 48%;
  max-width: 600px;
  height: 600px;
  border-radius: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.whySection > div > span {
  font-family: Lato;
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  text-align: left;
  position: absolute;
  left: 48px;
  bottom: 48px;
  width: 50%;
  color: #ffffff;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 20px 22px;
  }

  .container > button {
    margin: 1rem auto 0px;
  }

  .whySection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 1rem;
  }

  .whySection > div {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 420px;
  }

  .whySection > div > span {
    font-size: 26px;
    line-height: 150%;
    left: 18px;
    bottom: 18px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 20px 22px;
  }

  .container > button {
    margin: 1rem auto 0px;
  }

  .whySection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 1rem;
  }

  .whySection > div {
    flex-basis: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 420px;
  }

  .whySection > div > span {
    font-size: 26px;
    line-height: 150%;
    left: 18px;
    bottom: 18px;
  }
}
