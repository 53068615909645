.container {
  display: flex;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.leftSection {
  width: 106px;
  max-width: 106px;
  height: 100%;
  position: relative;
  flex-shrink: 0;
}

.leftSectionInner {
  width: 106px;
  height: 100%;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #dcdcdc;
}

.bodyContainer {
  width: calc(100% - 106px);
  flex: 1;
  min-height: 90vh;
  position: relative;
}

.header {
  position: sticky;
  top: 0;
  height: 10vh;
  background-color: #fff;
  z-index: 30;
}

.body {
  min-height: 90vh;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .leftSection {
    display: none;
  }

  .bodyContainer {
    width: 100%;
  }

  .footer {
    border-left: none;
  }
}
