.container {
  padding: 1rem 100px;
  overflow-x: hidden;
}

.dropdownSection {
  margin: 4rem auto;
  width: 70%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .container > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .dropdownSection {
    margin: 4rem auto;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1.5rem;
  }

  .dropdownSection {
    margin: 4rem auto;
    width: 100%;
  }
}
