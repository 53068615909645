.container {
  padding: 4rem 2rem;
  height: 100%;
}

.sideNavHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.sideNavHeader > img {
  width: 116px;
}

.container > .linksSection {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.container > .linksSection > a,
.container > .linksSection > div {
  display: block;
  color: var(--dark-sub, #1d2129);
  text-decoration: none;
  margin-bottom: 40px;
  position: relative;
}

.container > .linksSection > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.container > .linksSection > a > span:nth-child(2) {
  position: absolute;
  bottom: -85%;
  left: -5px;
}

.dropdown {
  width: 300px;
  margin: auto auto;
  background: #fff;
  animation: appear 0.3s ease-in-out;
  overflow-y: hidden;
  flex-basis: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.dropdown > a {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  display: block;
  padding: 0.75rem 1rem;
  text-align: center;
}

.dropdown > a:hover {
  background-color: #dcdcdc;
}

.active {
  color: var(--dark-sub, #1d2129);
}

.inActive {
  cursor: not-allowed;
  pointer-events: none;
  color: #a7a5a5;
}
