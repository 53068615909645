.button {
  padding: 1rem 0;
  border-radius: 4px;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border: none;
  cursor: pointer;
  width: 100%;
  cursor: pointer;
}

.primary {
  background: #037ef3;
  color: var(--White, #fff);
}

.secondary {
  color: var(--AIESEC-Blue, #037ef3);
  background: var(--White, #fff);
}

.nv {
  border-radius: 32px;
  display: flex;
  padding: 1rem 4rem;
  align-items: center;
  justify-content: center;
  gap: 13px;
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}

.nv {
  background: #00c16e;
  border: none;
  color: #ffffff;
}

.secondaryNv {
  background: #fff;
  color: #08841b;
  border: 1px solid #08841b;
}

.nv:disabled,
.nv:disabled {
  background-color: #b9b9b9;
  pointer-events: none;
  cursor: not-allowed;
}


@media screen and (min-width: 767px) and (max-width: 1023px) {
  .button {
    font-size: 14px;
    line-height: 23px;
    padding: 0.5rem 0;
  }

  .nv {
    font-size: 14px;
    line-height: 23px;
    padding: 0.5rem 0;
  }
}

@media screen and (max-width: 767px) {
  .button {
    font-size: 14px;
    line-height: 23px;
    padding: 0.5rem 0;
  }

  .nv {
    font-size: 14px;
    line-height: 23px;
    padding: 0.5rem 0;
  }
}
