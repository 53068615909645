.container {
  text-align: center;
  padding: 3rem 0;
}

.aboutContainer {
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.aboutContainer > h6 {
  color: var(--dark-main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}

.svgContainer{
  display: flex;
  justify-content: center;
  align-items: center;

}

.aboutContainer > p {
  color: var(--dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
  width: 60%;
  margin: 1em auto;
}

.aboutUserType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.aboutUserType > div {
  width: 387px;
  height: 276px;
  border: 1px solid #a0a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.aboutUserType > div > h4 {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.aboutUserType > div > p {
  color: var(--dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 1.5rem;
  width: 70%;
}

.aboutUserType > div > div > a {
  color: var(--dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.aboutUserType > div > div > a > svg{
  margin-left: 0.5rem;
}

.aboutUserType > div > div > svg:nth-child(3) {
  display: block;
}

.ourPartners {
  padding: 4rem 0;
}

.ourPartners > h6 {
  color: var(--dark-main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin: 1em 0;
}

.partnerImages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.partnerImages > img {
  max-width: 100px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    text-align: center;
    padding: 3rem 1.5rem;
  }

  .aboutContainer {
    margin-bottom: 2rem;
  }

  .aboutContainer > h6 {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }

  .aboutContainer > p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    width: 80%;
    margin: 1em auto;
  }

  .aboutUserType {
    flex-wrap: wrap;
  }

  .aboutUserType > div > h4 {
    font-size: 18px;
    line-height: 32px; /* 177.778% */
  }

  .aboutUserType > div > p {
    font-size: 16px;
    line-height: 24px;
    width: 70%;
    margin-bottom: 1rem;
  }

  .aboutUserType > div > div > a {
    font-size: 16px;
    line-height: 24px; /* 150% */
  }

  .aboutUserType > div {
    width: 325px;
  }

  .ourPartners {
    padding: 2rem 0;
  }

  .partnerImages {
    overflow-x: auto;
    flex-wrap: wrap;
    gap: 20px;
  }

  .partnerImages > img {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 10%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    text-align: center;
    padding: 3rem 1.5rem;
  }

  .aboutContainer {
    margin-bottom: 2rem;
  }

  .aboutContainer > h6 {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }

  .aboutContainer > p {
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
    width: 80%;
    margin: 1em auto;
  }

  .aboutUserType {
    flex-direction: column;
  }

  .aboutUserType > div > h4 {
    font-size: 18px;
    line-height: 32px; /* 177.778% */
  }

  .aboutUserType > div > p {
    font-size: 16px;
    line-height: 24px;
    width: 70%;
    margin-bottom: 1rem;
  }

  .aboutUserType > div > div > a {
    font-size: 16px;
    line-height: 24px; /* 150% */
  }
  .ourPartners {
    padding: 2rem 0;
  }

  .partnerImages {
    overflow-x: auto;
    flex-wrap: wrap;
    gap: 20px;
  }

  .partnerImages > img {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 10%;
  }

  .aboutUserType > div {
    width: 325px;
  }
}
