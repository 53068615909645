.title{
  font-family: 'Clash Display', sans-serif;
  color: rgb(91, 87, 244, 1);
}

.text{
  color: rgb(255, 204, 51, 1);
  font-weight: 700;
}

.ourWhatImg {
  background-image: url("../../Assets/Images/ourTheme.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.whiteBtn {
  text-decoration: none;
    padding: 14px 32px;
    background-color: transparent;
    border-radius: 8px;
    font-family: "Lato";
}
  
.sdgCards {
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .sdgCard:nth-child(1) {
    background-image: url("../../Assets/Images/SDG/sdg3.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sdgCard:nth-child(2) {
    background-image: url("../../Assets/Images/SDG/sdg8.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sdgCard:nth-child(3) {
    background-image: url("../../Assets/Images/SDG/sdg11.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sdgCard:nth-child(4) {
    background-image: url("../../Assets/Images/SDG/sdg17.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}