.textBlue {
    color: rgb(61, 141, 252, 1);
}

.textPrime {
    color: rgb(255, 204, 51, 1);
}

#register {
  min-height: 100vh;
  background-color: #161515;
}