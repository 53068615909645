.innerContainer {
  background-color: #037ef3;
  /* margin-top: 5rem;
  margin-bottom: 3rem; */
  padding-bottom: 40px;
}

.roles {
  display: flex;
  position: relative;
  margin-bottom: 50px;
}

.topImage {
  width: 127px;
  height: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 95px;
  top: 0;
  position: absolute;
}

.topImage > img {
  width: 96px;
}

.rolesText {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.rolesText > h4 {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  width: 100%;
}

.rolesText > p {
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
  width: 85%;
  text-align: center;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
}

.boxForm {
  background-color: #fff;
  width: 800px;
  /* margin: auto; */
  padding: 60px 80px;
  border-radius: 10px;
}

.oneChild,
.One > label {
  color: #52565e;
  font-size: 16px;
  font-weight: 700;
  font-family: Lato;
}

.One {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.One > .oneChild {
  width: 47%;
}

.oneChild > input:active {
  border: 1px solid #037ef3;
}

.oneChild > input {
  width: 100%;
  padding: 12px;
  border: 1px solid #caccd1;
  border-radius: 5px;
  color: #000;
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 5px;
}

.oneChild select {
  width: 100%;
  padding: 11px;
  border: 1px solid #caccd1;
  border-radius: 5px;
  color: #000;
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 5px;
  cursor: pointer;
}
.oneChild > input::placeholder {
  color: #caccd1;
}

.done {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.done > button {
  width: 240px;
  margin-top: 2rem;
  margin: 2rem auto 0 auto;
}

.error {
  margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .topImage {
    width: 110px;
    height: 79px;
    margin-left: 1.5rem;
  }

  .roles {
    flex-direction: column;
  }

  .rolesText {
    align-items: center;
  }

  .rolesText > h4 {
    font-size: 32px;
    margin-left: 5px;
    font-family: Lato;
    text-align: center;
  }

  .rolesText > p {
    font-size: 20px;
    width: 90%;
    margin-top: 1rem;
  }

  .boxForm {
    width: 400px;
    margin: auto;
    padding: 30px 40px;
  }

  .One {
    display: block;
    width: 100%;
  }

  .One > .oneChild {
    width: 100%;
  }

  .rolesText {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .topImage {
    width: 120px;
    height: 85px;
    margin-left: 1.5rem;
  }

  .roles {
    flex-direction: column;
  }

  .rolesText {
    align-items: center;
  }

  .rolesText > h4 {
    font-size: 32px;
    margin-left: 15px;
  }

  .rolesText > p {
    font-size: 20px;
    width: 80%;
    margin-top: 1rem;
  }

  .boxForm {
    width: 100%;
    margin: auto;
    padding: 30px 40px;
  }

  .One {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1.5em;
  }

  .One > .oneChild {
    width: 50%;
  }

  .rolesText {
    margin-top: 5rem;
  }
}
