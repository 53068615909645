.topSection {
  display: flex;

  padding: 30px 95px;
  gap: 82px;
}

.topSection > div:nth-child(1) {
  flex: 2;
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.topSection > div:nth-child(1) > a {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.16px;
  display: block;
  text-decoration: none;
}

.topSection > div:nth-child(2),
.topSection > div:nth-child(3),
.topSection > div:nth-child(4) {
  flex: 1;
}

.topSection > div:nth-child(2) > h4,
.topSection > div:nth-child(3) > h4,
.topSection > div:nth-child(4) > h4 {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 246.154% */
  letter-spacing: -0.13px;
}

.topSection > div:nth-child(2) > a,
.topSection > div:nth-child(3) > a,
.topSection > div:nth-child(4) > a {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
  text-decoration: none;
  display: block;
  margin: 0.2rem 0;
}

.bottomSection {
  margin: 0 95px;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #a0a0a0;
}

.bottomSection > div:nth-child(1) {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 200% */
  letter-spacing: -0.16px;
}

.bottomSection > div:nth-child(2) > a {
  padding: 0 11px;
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-decoration: none;
}

.bottomSection > div:nth-child(2) > a:nth-child(1) {
  border-right: 1px solid #037ef3;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .topSection {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  .topSection {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem 1.5rem;
  }

  .bottomSection {
    margin: 0 1.5rem;
    display: flex;
    flex-wrap: wrap;
  }
}
