.container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 4rem 100px;
}

.headerAndImage {
  flex-basis: 75%;
  padding: 4rem 0 7rem 0;
}

.imageHeading{
  display: flex;
  align-items: center;
  gap: 0.25rem;
}


.imageHeading span{
  color: #037EF3;
  font-weight: 600;
  font-size: 12px;
}
.headerAndImage > h1 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 48px;
  font-style: italic;
  font-weight: 300;
  line-height: 56px;
}

.headerAndImage > h1 > span {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
}

.informationSection {
  flex-basis: 75%;
  padding: 4rem 0 1rem;
  border-top: 1px solid var(--border, #dcdcdc);
}

.informationSection > h6 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 1rem;
}

.informationSection > p {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.18px;
  margin-bottom: 2rem;
}

.contactSection {
  flex: 25%;
  order: 0;
  padding: 3rem;
  border: 1px solid var(--border, #dcdcdc);
  background: #fff;
}

.iconsAndTopic {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
  cursor: pointer;
}

.iconsAndTopic > svg:nth-child(3) {
  margin-left: auto;
}

.iconsAndTopic > span {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}

.contactSection > a {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.18px;
  text-decoration: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .container > div {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .container > div {
    flex-basis: 100%;
  }
}
