#banner {
  background-color: #171616;
  color: white;
  overflow: hidden;
  position: relative;
}

.title {
  font-family: 'Clash Display', sans-serif;
  font-weight: 500;
  line-height: 1;
  z-index: 10;
  font-size: 40px;
  @media screen and (max-width: 750px) {
    font-size: 28px;
  }
}

.title span {
  font-weight: 700;
  color: var(--blue);
}

.boxImg {
  background-image: url("../../Assets/Images/banner-img.jpg");
  background-position: center;
  background-size: auto 530px;
  background-repeat: no-repeat;
  
  @media screen and (max-width:750px) {
    background-size: auto 320px;
  }
}