.container {
  padding: 4rem 100px;
  border-bottom: 1px solid #dcdcdc;
}

.container > h6 {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 446.25px;
  font-style: normal;
  font-weight: 700;
  line-height: 510px;
  letter-spacing: -17.85px;
}

.paragraph > p {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
  width: 556px;
}

.paragraph {
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .container > h6 {
    font-size: 140.458px;
    line-height: 160.523px;
    letter-spacing: -5.618px;
  }

  .paragraph > p {
    font-family: Lato;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem 1.5rem;
  }

  .container > h6 {
    font-size: 140.458px;
    line-height: 160.523px;
    letter-spacing: -5.618px;
  }

  .paragraph > p {
    line-height: 24px;
    width: 50%;
  }
}
