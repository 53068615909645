.blogHeading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    padding-left: 6rem;
    width:100%;
}

.blogHeading h1{
    font-size: 2rem;
    font-weight: 700;
    color: #20252F;
    font-style: italic;
    font-family:'Lato',sans-serif;
}

.blogHeadingTitle{
    width:30%;
}

.blogHeadingTitle > span{
    color: #037EF3;
    font-weight: 500;
    font-style: italic;
    line-height: 1.5;
}

.blogHeadingTitle h1{
    margin: 2rem 0;
}

.blogHeadingTitleAuthor{
    display: flex;
    align-items: center;
}

.blogHeadingTitleAuthor img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit:cover;
}

.blogHeadingTitleAuthor span{
    color: #20252F;
    font-weight: 500;
    font-size:1rem;
    margin-left:1rem;
    font-style: italic;
}

.blogHeadingImg{
    width:52%;
}

.blogHeadingImg img{
    width: 100%;
    height: 100%;
}

.blogContent{
    padding: 30px 95px;
    margin-top: 4rem;
    max-width: 950px;
    margin: 1rem auto;
}

.blogContent p{
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #000;
    margin:1rem 0;
    font-weight:300;
    font-family:'Lato',sans-serif;
}

.blogContent > p > a{
    color: #037EF3;
    font-weight: 500;
    font-style: italic;

}

.blogContent img{
    width: 100%;
    height: auto;
    margin: 2rem 0;
}

.blogsRelated{
    padding: 30px 95px;
    margin-top: 4rem;
    margin: 1rem auto;
}

.blogsRelated h4{
    font-size: 2rem;
    font-weight: 700;
    color: #20252F;
    font-style: italic;
    margin-bottom:2rem;
    font-family:'Lato',sans-serif;
}

.shareBlogContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
}

.shareBlogContent span{
    margin: 0;
    font-size: 1.125rem;
    font-weight: 700;
}

.shareBlogContent button:nth-of-type(1){
    margin-right: 1rem;
}

.shareBlogContent button{
    background: transparent;
    border: 0;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
}

.blogsRelatedContainer{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 3rem;
}

.featuredBlogLinkContainer {
    text-decoration: none;
}

.featuredBlogLinkContainer:hover img {
    transform: scale(0.99);
    transition: all 0.3s ease-in-out;
}

.featuredBlogImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuredBlogTitle {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 32px;
    margin-top: 1.75rem;
}

.featuredBlogDesc {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    text-overflow: ellipsis;
}

.featuredBlogLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    width: 100%;
}

.featuredBlogLink>span {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    line-height: 24px;
}

.blogCard {
    display: flex;
    gap: 1rem;
    width: 100%;
    max-width: 552px;
    background-color: #fff;
    height: 227px;
}

.blogCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
}



.blogCardImgContainer {
    width: 100%;
    max-height: 227px;
}

.blogsRelatedContainer .blogCardTitle {
    margin: 0;
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 700;
    line-height: 32px;
}


@media only screen and (max-width: 769px){
    .blogHeading{
        flex-direction: column-reverse;
        padding:0;
    }
    .blogHeadingTitle{
        width: 100%;
        padding: 1rem 2rem;
    }
    .blogHeadingImg{
        width:100%;
    }
    .blogHeadingTitleAuthor img{
        width: 44px;
        height: 44px;
    }
    .blogHeadingTitle h1{
        margin: 1rem 0;
    }
    .blogContent{
        padding:1rem;
    }
    .blogContent p{
        font-size: 1rem;
    }
    .blogsRelated{
        padding: 1rem;
    }
    .blogsRelatedContainer{
        grid-template-columns: repeat(1,1fr);
    }
}