.container {
  padding: 4rem 0 4rem 100px;
  width: 70%;
}

section.container h1, h2, h4, h6{
  color: var(--Dark-Main, #20252f);
}

.container p{
  color: var(--Dark-Text, #1D2129);
}

.container h6{
  font-size: 16px;
  font-weight: 700;
}

.ebPerson > div > p {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: -0.016px;
}


.container > h4 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.ebInfoSection {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(266px, 267px));
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  /* max-width: 96%; */
  margin-top: 2rem;
}

.ebPerson {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 266px;
  height: 258px;
  flex-shrink: 0;
}

.ebPerson > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.ebPerson > div:nth-child(2) {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.5px);
  padding: 1rem 1.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 2rem 1.5rem;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 2rem 1.5rem;
    width: 100%;
  }

  .ebPerson {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .ebPerson > div:nth-child(2){
    padding: 0.5rem;
    width: 90%;
    left: 0.5rem;
    bottom: 0.5rem;
  }

  .ebPerson > div > h6 {
    font-size: 14px;
    font-weight: 700;
  }

  .ebPerson > div > p {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    letter-spacing: -0.016px;
  }

  .ebInfoSection {
    display: grid;
    grid-template-columns:repeat(auto-fill, minmax(160px, 1fr));
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }
}
