.container {
  width: calc(100vw - 106px);
}

.topImage {
  width: 127px;
  height: 103px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 95px;
}

.topImage > img {
  width: 96px;
}

.containerMain {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 95px;
  margin-bottom: 13rem;
  padding-bottom: 1rem;
}

.containerMain > div:nth-child(1) > h2 {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  margin: 0.5em 0;
}

.containerMain > div:nth-child(1) > p {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.2px;
  width: 80%;
  margin: 1em 0;
}

.productMoreDetails {
  display: flex;
  align-items: center;
  gap: 118px;
}

.productMoreDetails > div {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.productMoreDetails > div > div:nth-child(1) {
  width: 24px;
  height: 24px;
  background: #fff;
}

.productMoreDetails > div > div:nth-child(2) > h5 {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.6;
  margin: 0.2em 0;
}

.productMoreDetails > div > div:nth-child(2) > p {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.learnMore > a {
  color: var(--white, #fff);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.learnMore > a > svg {
  margin-left: 0.5rem;
}

.learnMore > a > svg:nth-child(2) {
  display: block;
}

.popularOppurtunities {
  background: #fff;
  width: calc(100vw - 106px);
  position: relative;
  height: 300px;
}

.popularOppurtunitiesInner > h4 {
  margin-bottom: 24px;
  position: sticky;
  left: 0;
}

.popularOppurtunitiesInner {
  color: var(--white, #fff);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow-y: hidden;

  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;
  position: relative;
  top: -100px;
  padding: 0 95px;
  scroll-behavior: smooth;
}

.popularOppurtunitiesInner::-webkit-scrollbar {
  display: none;
}

.popularOppurtunitiesInner {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.popularOppurtunitiesInner > div {
  display: flex;
  align-items: center;
  gap: 24px;
}

.oppurtunuty {
  width: 266px;
  height: 258px;
  flex-shrink: 0;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 266px;
  cursor: pointer;
}

.oppurtunuty > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.oppurtunutyText {
  position: absolute;
  left: 16px;
  bottom: 16px;
  right: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.5px);
  padding: 16px 22px;
}

.oppurtunutyText > h6 {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.oppurtunutyText > p,
.noOppurtunities {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.noOppurtunities {
  text-align: center;
  padding-top: 3rem;
}

.toggleScroll {
  position: absolute;
  margin-top: 1.5rem;
  bottom: 60px;
  left: 120px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.toggleScroll > svg {
  cursor: pointer;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  /* @media screen and (min-width: 1000px) and (max-width: 1500px) { */
  .container {
    width: calc(100vw - 106px);
  }

  .popularOppurtunities {
    width: calc(100vw);
  }

  .topImage {
    width: 110px;
    height: 79px;
    background: #000;
    margin-left: 1.5rem;
  }

  .containerMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 1.5rem;
    margin-bottom: 10rem;
    flex-direction: column;
    gap: 2rem;
  }

  .containerMain > div:nth-child(1) > h2 {
    color: var(--dark-main, #20252f);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .containerMain > div:nth-child(1) > p {
    color: var(--dark-main, #20252f);
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.16px;
    width: 90%;
  }

  .productMoreDetails {
    align-items: flex-start;
    gap: 2rem;
    flex-direction: column;
  }

  .productMoreDetails > div > div:nth-child(1) {
    width: 24px;
    height: 24px;
    background: #fff;
  }

  .productMoreDetails > div > div:nth-child(2) > h5 {
    color: var(--dark-main, #20252f);
    font-size: 16px;
    line-height: 24px;
  }

  .productMoreDetails > div > div:nth-child(2) > p {
    color: var(--dark-main, #20252f);
    font-size: 16px;
    line-height: 24px;
  }

  .popularOppurtunitiesInner {
    color: var(--white, #fff);
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
    position: relative;
    top: -100px;
    padding: 0 1.5rem;
  }

  .popularOppurtunitiesInner > h4 {
    color: var(--dark-main, #20252f);
    font-size: 18px;
    line-height: 28px;
  }

  .toggleScroll {
    position: absolute;
    margin-top: 1.5rem;
    bottom: 60px;
    left: 24px;
    display: flex;
    gap: 1.5rem;
  }

  .popularOppurtunities {
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 100vw;
  }

  .popularOppurtunities {
    width: calc(100%);
  }

  .topImage {
    width: 110px;
    height: 79px;
    background: #000;
    margin-left: 1.5rem;
  }

  .containerMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 1.5rem;
    margin-bottom: 10rem;
    flex-direction: column;
    gap: 2rem;
  }

  .containerMain > div:nth-child(1) > h2 {
    color: var(--dark-main, #20252f);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .containerMain > div:nth-child(1) > p {
    color: var(--dark-main, #20252f);
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.16px;
    width: 90%;
  }

  .productMoreDetails {
    align-items: flex-start;
    gap: 2rem;
    flex-direction: column;
  }

  .productMoreDetails > div > div:nth-child(1) {
    width: 24px;
    height: 24px;
    background: #fff;
  }

  .productMoreDetails > div > div:nth-child(2) > h5 {
    color: var(--dark-main, #20252f);
    font-size: 16px;
    line-height: 24px;
  }

  .productMoreDetails > div > div:nth-child(2) > p {
    color: var(--dark-main, #20252f);
    font-size: 16px;
    line-height: 24px;
  }

  .popularOppurtunitiesInner {
    color: var(--white, #fff);
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
    position: relative;
    top: -100px;
    padding: 0 1.5rem;
  }

  .popularOppurtunitiesInner > h4 {
    color: var(--dark-main, #20252f);
    font-size: 18px;
    line-height: 28px;
  }

  .toggleScroll {
    position: absolute;
    margin-top: 1.5rem;
    bottom: 60px;
    left: 24px;
    display: flex;
    gap: 1.5rem;
  }
}
