.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  padding: 0 100px;
  gap: 100px;
}

.textSection {
  width: 50%;
}

.textSection > p:nth-child(1) {
  color: var(--aiesec-black, #52565e);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 300;
  line-height: 64px;
}

.textSection > p {
  margin: 1em 0;
}

.textSection > p:nth-child(1) > span {
  color: var(--aiesec-blue, #037ef3);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 600;
  line-height: 64px;
}

.textSection > p:nth-child(2) {
  color: var(--aiesec-black, #52565e);
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.textSection > button {
  color: var(--aiesec-white, #f3f4f7);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 16px;
  border-radius: 8px;
  background: var(--aiesec-blue, #037ef3);
  border: none;
}

.imageSection {
  width: 50%;
}

.imageSection > img {
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding: 40px 24px;
    gap: 100px;
  }

  .container > div {
    width: 100%;
  }

  .textSection > p:nth-child(1),
  .textSection > p:nth-child(1) > span {
    font-family: Lato;
    font-size: 36px;
    font-style: italic;
  }

  .textSection > p:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
  }

  .textSection > button {
    font-size: 14px;
    line-height: 21px;
    padding: 12px;
    border-radius: 8px;
    border: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding: 40px 24px;
    gap: 100px;
  }

  .container > div {
    width: 100%;
  }

  .textSection > p:nth-child(1),
  .textSection > p:nth-child(1) > span {
    font-family: Lato;
    font-size: 36px;
    font-style: italic;
  }

  .textSection > p:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
  }

  .textSection > button {
    font-size: 14px;
    line-height: 21px;
    padding: 12px;
    border-radius: 8px;
    border: none;
  }
}
