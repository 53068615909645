.ourWhatImg {
    background-image: url("../../Assets/Images/ourWHat.jpg");
    background-position: top -70px center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 500px) {
        background-position: top center;
    }
}

@media (min-width: 768px) {
    .ourWhatImg {
        height: 24rem;
    }
}
