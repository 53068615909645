@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=clash-display@400,500,600&display=swap');

:root {
  --primary-color: #ffcc33;
  --blue: #3d8dfc;
  --grey: #A7A2A2;

}

@font-face {
  font-family: "Regular";
  src: local("Expose-Regular"), url("./fonts/Expose-Regular.ttf") format("truetype");
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: 'Lato', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.fl{
  font-family: 'Raleway', sans-serif;
}

.fcd {
  font-family: 'Clash Display', sans-serif;
}

/* Shadows */
.b-shade {
  box-shadow: 8px 8px 2px -4px rgba(0, 0, 0);
}

.p-shade {
  box-shadow: 8px 8px 2px -4px #5b57f4;
}

.text-prime{
    color: rgb(255, 204, 51, 1);
}

.text-purple{
  font-family: 'Clash Display', sans-serif;
  color: rgb(91, 87, 244, 1);
}

.text-apurple {
  --tw-text-opacity: 1;
  color: rgb(176 126 246 / var(--tw-text-opacity)) /* #b07ef6 */;
}

.text-blue {
  color: rgb(61, 141, 252, 1);
}

.text-grey {
  color: rgb(167, 162, 162, 1);
}

.primary-btn{
  text-decoration: none;
  padding: 12px 30px;
  background-color: var(--primary-color);
  border-radius: 8px;
  font-family: "Lato";

  @media screen and (max-width:500px) {
    border-radius: 4px;
    padding: 12px 16px;
  }
}

.white-btn {
  text-decoration: none;
  padding: 14px 32px;
  background-color: transparent;
  border-radius: 8px;
  font-family: "Lato";

  @media screen and (max-width:500px) {
    padding: 14px 20px;
  }
}

/* Animation */
.spinner{
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 15px;
  height: 15px;
}

.spinner .path {
  stroke: #000000;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
  }

  50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
  }

  100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
  }
}