.container {
  display: grid;
  /* align-items: center; */
  /* justify-content: center; */
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* gap: 12px; */
  /* flex-wrap: wrap; */
  padding: 96px 128px;
  background-color: #ECF5FC;
}

.detailsContainer > :nth-child(1){
  flex-basis: 48%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 408px;
  min-height: 290px;
  /* max-width: 620px; */
  /* min-height: 441px; */
  color: #fff;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: relative;
}

.detailsButton{
  position: absolute;
  bottom: 0;
  background-color: #000000C7;
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.detailsBlock {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.detailsText {
  font-weight: 700;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 28px;
  }

  .container > div {
    flex-basis: 100%;
    max-width: 100%;
    min-height: 341px;
    font-family: Lato;
    font-size: 20px;
    border-radius: 20px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    display: block;
    padding: 28px;
    /* gap: 12px; */
  }

  .container > div {
    flex-basis: 100%;
    max-width: 100%;
    min-height: 341px;
    font-family: Lato;
    font-size: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
}
