.container {
  background: #000;
  padding: 90px 235px;
  background: url('../../Assets/Images/prohectPageHeader.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container > h4 {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.container > p {
  color: #fff;
  font-family: Raleway;
  font-size: 52px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 5rem;
  text-align: center;
}

.container > button {
  margin-top: 2rem;
  width: 50%;
  margin: 2rem auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 30px;
  }

  .container > h4 {
    color: #fff;
    font-size: 20px;
  }

  .container > p {
    font-size: 16px;
    margin-top: 3rem;
  }

  .container > button {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 20px;
  }

  .container > h4 {
    color: #fff;
    font-size: 20px;
  }

  .container > p {
    font-size: 16px;
    margin-top: 3rem;
  }

  .container > button {
    width: 90%;
  }
}
