input,
select {
    width: 100%;
    padding: 24px 32px;
    border: 3px solid #ffffff;
    border-radius: 8px;
    background: transparent;
    color: #ffffff;
    font-size: 20px;
}

input::placeholder,
select::placeholder {
    color: #909090;
}

input:focus,
select:focus {
    outline: none;
}

@media screen and (max-width: 600px) {
    input,
    select {
        padding: 20px 12px;
        font-size: 16px;
        border-width: 1px;
    }
}

.txt-ggrey {
    color: #909090 !important;
}

.select-input {
    position: relative;
}

.select-input select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
}

.select-input select::-ms-expand {
    display: none;
}

.select-input select:focus {
    outline: none;
}

@media screen and (max-width: 1000px) {
    .select-input select {
        width: 100%;
    }
}

.select-input select:-internal-list-box {
    background-color: #000000;
}

.select-input select option {
    background: black !important;
    color: #ffffff !important;
}

.select-input select option:first-child:checked {
    color: #909090 !important;
}

.select-input select option:focus {
    background-color: var(--primary-color);
}

.select-input .arrow-icon {
    content: url('../../Assets/Images/downArrow.svg');
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 1;
}

.text-danger {
    color: #eb0d0d;
    margin-block: 10px;
}