.container {
  min-height: calc(90vh);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.upperSection {
  padding: 0 290px;
  background: url('../../Assets/Images/formHeader.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-basis: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upperSection > h4 {
  color: #fff;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 130.5%;
}

.upperSection > h4 > span {
  color: #08841b;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 130.5%;
}

.lowerSection {
  flex-basis: calc(100vh - 10vh - 25vh);
  max-height: calc(100vh - 10vh - 25vh);
  display: flex;
  align-items: stretch;
}

.lowerSection > div {
  flex-basis: 50%;
}

.lowerSection > div:nth-child(1) {
  background: #08841b;
  padding: 56px 100px 56px 100px;
}

.lowerSection > div:nth-child(1) > p {
  color: #fff;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2rem;
}

.lowerSection > div:nth-child(1) > i {
  color: #fff;
  font-family: Lato;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 5rem;
  display: block;
}

.lowerSection > div:nth-child(2) {
  overflow-y: auto;
  padding: 54px 96px;
}

.lowerSection > div:nth-child(2) > form > h4 {
  color: #000;
  font-family: Lato;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 1.5rem;
}

.lowerSection > div:nth-child(2) > form > button {
  margin-top: 100px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    min-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .upperSection {
    padding: 0 20px;
    flex-basis: 160px;
  }

  .upperSection > h4,
  .upperSection > h4 > span {
    font-family: Lato;
    font-size: 20px;
    line-height: 130.5%;
  }

  .lowerSection {
    flex-basis: auto;
    max-height: 3000px;
    flex-direction: column;
  }

  .lowerSection > div {
    flex-basis: auto;
    width: 100%;
  }

  .lowerSection > div:nth-child(1) {
    background: #08841b;
    padding: 34px 27px;
  }

  .lowerSection > div:nth-child(1) > p,
  .lowerSection > div:nth-child(1) > i {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 1rem;
    margin-top: 0rem;
  }

  .lowerSection > div:nth-child(2) {
    padding: 34px 27px;
  }

  .lowerSection > div:nth-child(2) > form > h4 {
    color: #000;
    font-size: 20.381px;
    line-height: normal;
  }

  .lowerSection > div:nth-child(2) > form > button {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    min-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  .upperSection {
    padding: 0 20px;
    flex-basis: 160px;
  }

  .upperSection > h4,
  .upperSection > h4 > span {
    font-family: Lato;
    font-size: 20px;
    line-height: 130.5%;
  }

  .lowerSection {
    flex-basis: auto;
    max-height: 3000px;
    flex-direction: column;
  }

  .lowerSection > div {
    flex-basis: auto;
    width: 100%;
  }

  .lowerSection > div:nth-child(1) {
    background: #08841b;
    padding: 34px 27px;
  }

  .lowerSection > div:nth-child(1) > p,
  .lowerSection > div:nth-child(1) > i {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 1rem;
    margin-top: 0rem;
  }

  .lowerSection > div:nth-child(2) {
    padding: 34px 27px;
  }

  .lowerSection > div:nth-child(2) > form > h4 {
    color: #000;
    font-size: 20.381px;
    line-height: normal;
  }

  .lowerSection > div:nth-child(2) > form > button {
    margin-top: 40px;
  }
}
