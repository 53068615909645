.container {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.container>h4 {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 56px;
    font-style: italic;
    font-weight: 300;
    line-height: 64px;
    width: 843px;
    text-align: center;
    margin-top: 2rem;
}

.container>h4>span {
    position: relative;
}

.container>h4>span>span:nth-child(1) {
    color: var(--Dark-Main, #20252f);
    text-align: center;
    font-family: Lato;
    font-size: 56px;
    font-style: italic;
    font-weight: 700;
    line-height: 64px;
}

.container>h4>span>svg:nth-child(2) {
    position: absolute;
    left: -20px;
    right: -20px;
    margin: auto;
    bottom: -8px;
    width: 165px;
}

.featuredBlogLinkContainer {
    text-decoration: none;
}

.featuredBlogLinkContainer:hover img {
    transform: scale(0.99);
    transition: all 0.3s ease-in-out;
}

.blogContainer {
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    padding: 30px 95px;
    margin-top: 4rem;
}

.featuredBlog {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 552px;
    height: 468px;
    background-color: #fff;
}

.featuredBlogImgContainer {
    width: 100%;
    height: 252px;
}

.featuredBlogImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featuredBlogTitle {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: 32px;
    margin-top: 1.75rem;
}

.featuredBlogDesc {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 1rem 0;
    text-overflow: ellipsis;
}

.featuredBlogLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    width: 100%;
}

.featuredBlogLink>span {
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 700;
    line-height: 24px;
}

.blogCard {
    display: flex;
    gap: 1rem;
    width: 100%;
    max-width: 552px;
    background-color: #fff;
    height: 227px;
}

.blogCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
}



.blogCardImgContainer {
    width: 100%;
    max-height: 227px;
}

.blogCardTitle {
    margin-top: 0;
    color: var(--Dark-Main, #20252f);
    font-family: Lato;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 700;
    line-height: 32px;
}

@media screen and (max-width: 767px) {
    .container>h4 {
        font-size: 2rem;
        width: 100%;
        margin-top: 2rem;
    }

    .featuredBlog{
        height: auto;
    }
    .container>h4>span>span:nth-child(1) {
        font-size: 2rem;
    }


    .blogContainer {
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 1.5rem;
    }

    .blogCard{
        align-items: center;
    }

    .blogCard .blogCardTitle{
        font-size: 1rem;
    }

    .blogCard .blogCardContainer{
        gap: 0.75rem;
    }
    .featuredBlogImgContainer {
        width: 100%;
    }

    .blogCard .featuredBlogImgContainer {
        width: 100%;
    max-height: 127px;
    }

    .blogCard .featuredBlogDesc{
        margin: 0;
        font-size: 0.75rem;
    }
    .blogCard .featuredBlogLink > span{
        font-size: 0.75rem;
    }

    .blogCardImgContainer{
        width: 100%;
    max-height: 127px;
    }

    .featuredBlogContainer:nth-of-type(2),
    .featuredBlogContainer:nth-of-type(3) {
        margin-top: 1rem;
    }

    .container>h4>span>svg:nth-child(2) {
        position: absolute;
        left: -20px;
        right: -20px;
        margin: auto;
        bottom: -8px;
        width: 6rem;
    }

}