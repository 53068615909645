@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  height: 10vh;
  display: flex;
  align-items: center;
  gap: 43px;
  justify-content: space-between;
  padding-right: 100px;
}

.responsiveContainer {
  height: 10vh;
  display: none;
  align-items: center;
  gap: 43px;
  justify-content: space-between;
  padding-right: 100px;
}

.logoAndSearch {
  display: flex;
  align-items: center;
  height: 42px;
  align-self: center;
  transition: all 0.3s ease-in-out;
}

.logoAndSearch > hr {
  width: 91px;
  border: 1px solid #dcdcdc;
}

.logoAndSearch > a > img {
  margin-right: 1.5rem;
  max-width: 150px;
  width: 150px;
}

.search {
  position: relative;
  height: 100%;
  border-left: 1px solid #dcdcdc;
  display: none;
}

.search > svg:nth-child(1) {
  position: absolute;
  left: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;
}

.search > svg:nth-child(3) {
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}

.search > input {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  text-indent: 40px;
  outline: none;
  height: 100%;
  width: 100%;
}

.search > input::placeholder {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
}

.memberButton {
  width: 222px;
  display: block;
}

.routesSection {
  display: flex;
  align-items: center;
  gap: 43px;
  animation: appear 0.3s ease-in-out;
  margin-left: auto;
}

.routesSection > a,
.routesSection > div {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  height: 50%;
  display: block;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.routesSection > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  position: relative;
}

.activeRouteIndicator {
  position: absolute;
  bottom: -100%;
  left: -30px;
  right: 0;
  margin: auto;
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.activeRouteIndicator > svg {
  min-width: 72px;
  max-width: 100%;
  width: 100%;
}

.sideNav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  overflow: hidden;
  width: 0;
  background-color: #ffffff;
  transition: 0.3s;
}

.sideNav > .innerContainer {
  padding: 4rem 2rem;
  height: 100%;
}

.sideNavHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sideNavHeader > img {
  width: 116px;
}

.sideNav > .innerContainer > .linksSection {
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.sideNav > .innerContainer > .linksSection > a {
  display: block;
  color: var(--dark-sub, #1d2129);
  text-decoration: none;
  margin-bottom: 56px;
}

.sideNavIcon {
  display: none;
}

.dropdown {
  position: absolute;
  min-height: 100px;
  max-height: 1000px;
  min-width: 200px;
  top: 130%;
  right: 0;
  z-index: 3;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  animation: appear 0.3s ease-in-out;
}

.dropdown > a {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  display: block;
  padding: 0.75rem 1rem;
}

.dropdown > a:first-child {
  border-radius: 8px 8px 0 0;
}

.dropdown > a:last-child {
  border-radius: 0px 0px 8px 8px;
}

.dropdown > a:hover {
  background-color: #dcdcdc;
}

.active {
  color: var(--dark-sub, #1d2129);
}

.inActive {
  cursor: not-allowed;
  pointer-events: none;
  color: #a7a5a5;
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .container {
    display: none;
  }

  .logoAndSearch > a > img {
    margin-right: 1.5rem;
    max-width: 150px;
    width: 150px;
  }

  .responsiveContainer {
    display: flex;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 1rem;
    width: 100%;
  }

  .routesSection {
    display: none;
  }
  .search {
    display: none;
  }

  .logoAndSearch > hr {
    display: none;
  }

  .logoAndSearch > a > img {
    margin-right: 1.5rem;
    max-width: 150px;
    width: 150px;
  }

  .sideNavIcon {
    display: block;
    margin-left: auto;
  }

  .memberButton {
    width: 160px;
    display: block;
  }
}
