.container {
    min-height: calc(100vh - 10vh);
    background-color: #FCEFEF;
    padding-bottom: 2rem;
    
}

.showcaseContainer {
    background-image: url('../../Assets/Images/spiral.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FFDE00;
    padding: 100px 30px;
    border-radius: 25px;
}

.showcaseText {
    display: block;
    text-align: center;
}

.showcaseText > h4 {
    font-size: 32px;
    font-family: Regular;
    font-weight: bolder;
    color: #000000;
    margin-bottom: 1rem;
}

.showcaseText > p {
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
}

.showcaseImageSection {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.showcaseOne > h5 {
    font-weight: 600;
    font-style: italic;
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.showcaseOne > p {
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    width: 70%;
}

.showcaseImage {
    margin-left: 120px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.showcaseTwo > h5 {
    font-weight: 600;
    font-style: italic;
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.showcaseTwo > p {
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    width: 55%;
}

.NextSection {
    background-color: #fff;
    padding: 100px 50px;
    border-radius: 20px;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.NextTextSection {
    display: block;
    text-align: center;
}

.NextTextSection > h4 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-family: Regular;
}

.NextTextSection > p {
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 2rem;
    margin-right: auto;
    margin-left: auto;
    width: 55%;
}

.ExploreButton {
    background-color:#008F4F ;
    padding: 10px 20px;
    border-radius: 10px;
    margin: auto;
    width: 20%;
}

.ExploreButton a {
    color: #fff;
    text-decoration: none;
    display: block;
    text-align: center;
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .showcaseText > h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    
    .showcaseText > p {
        font-size: 16px;
        width: 100%;
    }

    .showcaseContainer {
        padding: 60px 15px;
    }

    .showcaseImageSection {
        gap: 10px;
    }
    
    .showcaseOne > p {
        width: 100%;
    }

    .showcaseImage {
        margin-left: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        /* align-items: center;
        justify-content: space-between; */
    }
    
    .showcaseTwo > p {
        width: 100%;
    }

    .NextSection {
        padding: 50px 10px;
        border-radius: 20px;
    }
    
    
    .NextTextSection > p {
        width: 100%;
    }

    .ExploreButton {
        width: 50%;
    }

}