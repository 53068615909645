.container {
  width: 106px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container > div:not(.container > div:nth-child(5)) {
  width: 100%;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dcdcdc;
}

.container > div:nth-child(1) {
  border-top: 1px solid #dcdcdc;
}

.container > div:nth-child(5) {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-top: 0rem;
  position: relative;
  width: 100%;
  height: 236px;
}

.container > div:nth-child(5) > p {
  white-space: nowrap;
  transform: rotate(90deg);
  position: absolute;
  top: 98px;
  text-align: center;
}

.container > div:nth-child(5) > svg {
  position: absolute;
  bottom: 0;
}
