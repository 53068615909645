.heroImageSection {
  width: 100%;
}

.heroImageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4rem 100px;
}

.titleSection > h2 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 56px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
}

.titleSection > h2 > span {
  display: block;
}

.logoImage {
  width: 100%;
  height: auto;
}

.aboutText {
  font-style: italic;
}

.aboutContainer {
  margin-bottom: 4rem;
  margin-top: 96px;
}

.aboutContainerText {
  background-color: #037ef3;
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.aboutContainer > .aboutContainerText > h6 {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 1rem;
}

.aboutContainer > .aboutContainerText > p {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  line-height: 32px;
  letter-spacing: -0.2px;
  width: 85%;
  margin: 1em auto;
}

.mediaView {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 85%;
  margin: 0 auto;
  overflow: hidden;
}

.videoFrame {
  width: 100%;
  height: 450px;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;
  z-index: 2;
}

.roles {
  background-color: #037ef3;
  display: flex;
  position: relative;
}

.topImage {
  width: 127px;
  height: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 95px;
  position: absolute;
  top: 0;
}

.topImage > img {
  width: 96px;
}

.rolesText {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 70%;
  text-align: center;
}

.rolesText > h2 {
  color: #fff;
  font-weight: 700;
  font-size: 48px;
  font-style: normal;
  text-transform: uppercase;
  line-height: 56px;
}

.rolesText > p {
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.2px;
  width: 90%;
  text-align: center;
  margin-top: 1rem;
}

.blueDash {
  background-color: #037ef3;
  height: 25px;
}
.duration {
  display: flex;
  align-items: center;
  gap: 158px;
  padding: 40px 0;
  margin: 0 100px;
  border-top: 1px solid #dcdcdc;
}

.duration > div > span:nth-child(1) {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 250;
  line-height: 24px;
  display: block;
}

.duration > div > span:nth-child(2) {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: block;
  border: 1px solid red;
}

.durationButtonSection {
  margin-left: auto;
  width: 178px;
}

.reasons {
  padding: 40px 100px;
  display: flex;
  align-items: center;
  border-top: 1px solid #dcdcdc;
  gap: 1rem;
}

.reasons > div {
  flex-basis: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reasons > div > div:nth-child(1) {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 50%;
  opacity: 0.3;
  background: #c4c4c4;
}

.reasons > div > h5 {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0.3em 0;
}

.reasons > div > p {
  color: var(--Dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  margin: 0.5em 0;
  width: 80%;
}

.testimonials {
  padding: 88px 0;
  background: #037ef3;
  width: calc(100vw - 106px);
}

.testimonials > h4 {
  color: var(--White, #fff);
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
  width: 65%;
  padding: 0 100px;
  margin-bottom: 4rem;
}

.testimonial {
  width: 284px;
  height: 228px;
  flex-shrink: 0;
  padding: 2rem 1.5rem;
  background: #fff;
}

.testimonial > p {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.48px;
}

.testimonial > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 2rem;
}

.testimonial > div:nth-child(2) > img:nth-child(1) {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.testimonial > div:nth-child(2) > div:nth-child(2) > h6 {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.48px;
}

.testimonial > div:nth-child(2) > div:nth-child(2) > p {
  color: var(--Dark-sub, #1d2129);
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.3px;
}

.testimonialSection {
  position: relative;
}

.testimonialScrollContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  overflow-x: auto;
  padding-left: 100px;
  width: 100%;
}

.testimonialScrollContainer::-webkit-scrollbar {
  display: none;
}

.testimonialScrollContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.testimonialLeft {
  position: absolute;
  width: 104px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.3;
  background: #037ef3;
}
.testimonialRight {
  position: absolute;
  width: 104px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0.3;
  background: #037ef3;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .titleSection {
    padding: 20px;
    flex-direction: column;
    gap: 1rem;
  }

  .titleSection > h2 {
    order: 1;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    /* width: 450px; */
  }

  .partnerSection {
    display: flex;
    max-width: 80%;
    text-align: left;
  }

  .partnerLogoSection {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .partnerSection > h3 {
    font-size: 20px;
    font-weight: 700;
  }

  .topImage {
    width: 120px;
    height: 85px;
    margin-left: 1.5rem;
  }

  .aboutContainer > .aboutContainerText > h6 {
    font-size: 40px;
  }

  .roles {
    flex-direction: column;
  }

  .rolesText {
    align-items: center;
    margin-top: 0px;
    width: 80%;
  }

  .rolesText > h2 {
    font-size: 32px;
    margin-left: 0px;
  }

  .rolesText > p {
    font-size: 20px;
    width: 80%;
    margin-top: 1rem;
  }

  .duration {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
    margin: 0;
  }

  .durationButtonSection {
    margin-left: none;
    width: 100%;
  }

  .reasons {
    padding: 40px 24px;
    flex-direction: column;
    gap: 3rem;
  }

  .testimonials {
    padding: 4rem 0;
    background: #037ef3;
    width: 100vw;
    box-sizing: border-box;
  }

  .testimonials > h4 {
    color: var(--White, #fff);
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 80%;
    padding: 0 24px;
    margin-bottom: 2rem;
  }

  .testimonialLeft,
  .testimonialRight {
    width: 44px;
  }

  .rolesText {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 767px) {
  .titleSection {
    padding: 20px;
    flex-direction: column;
    gap: 1rem;
  }

  .titleSection > h2 {
    order: 1;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
  }

  .partnerSection {
    display: flex;
    max-width: 80%;
    text-align: left;
  }

  .partnerLogoSection {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .partnerSection > h3 {
    font-size: 20px;
    font-weight: 700;
  }

  .topImage {
    width: 55px;
    height: 64px;
    margin-left: 1.5rem;
  }

  .aboutContainer > .aboutContainerText > h6 {
    font-size: 30px;
  }

  .roles {
    flex-direction: column;
  }

  .rolesText {
    align-items: center;
    margin: 1rem 20px;
    width: 90%;
  }

  .rolesText > h2 {
    font-size: 32px;
    margin-left: 0px;
  }

  .rolesText > p {
    font-size: 20px;
    width: 90%;
    margin-top: 1rem;
  }

  .duration {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding: 24px;
    margin: 0;
  }

  .durationButtonSection {
    margin-left: none;
    width: 100%;
  }

  .reasons {
    padding: 40px 24px;
    flex-direction: column;
    gap: 3rem;
  }

  .testimonials {
    padding: 4rem 0;
    background: #037ef3;
    width: 100vw;
    box-sizing: border-box;
  }

  .testimonials > h4 {
    color: var(--White, #fff);
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
    width: 80%;
    padding: 0 24px;
    margin-bottom: 2rem;
  }

  .testimonialLeft,
  .testimonialRight {
    width: 44px;
  }

  .rolesText {
    margin-top: 5rem;
  }

  .mediaView {
    padding: 40px;
  }
}
