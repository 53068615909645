.container {
  padding: 5rem 0;
}

.container > p {
  color: var(--dark-main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin: 1em 0;
}

.container > h4 {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.contentContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  padding: 7rem 2rem;
}

.content:nth-child(2) {
  margin-top: 0rem;
}

.content > .imageSection {
  width: 100%;
  height: 504px;
}

.content > .imageSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content > .textSection {
  padding-top: 2rem;
}

.content > .textSection > h4 {
  color: var(--dark-main, #20252f);
  font-family: Lato;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: 40px;
}

.content > .textSection > p {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.18px;
  width: 75%;
  margin: 1em 0;
}

.content > .textSection > a {
  color: var(--dark-sub, #1d2129);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.content > .textSection > a > svg{
  margin-left: 0.5rem;
}

.explore {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.explore > a {
  color: var(--dark-sub, #1d2129);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.explore > a > svg{
  margin-left: 0.5rem;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 4rem 1.5rem;
  }

  .container > p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }

  .container > h4 {
    font-size: 32px;
    line-height: 24px;
  }

  .contentContainer {
    align-items: center;
    gap: 40px;
    padding: 3rem 0;
    flex-direction: column;
    width: 100%;
  }

  .content:nth-child(2) {
    margin-top: 0rem;
  }

  .content > .imageSection {
    width: 100%;
    height: 298px;
  }

  .content > .textSection > h4 {
    font-size: 24px;
    line-height: 32px;
  }

  .content > .textSection > p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 4rem 1.5rem;
  }

  .container > p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }

  .container > h4 {
    font-size: 32px;
    line-height: 24px;
  }

  .contentContainer {
    align-items: center;
    gap: 40px;
    padding: 3rem 0;
    flex-direction: column;
    width: 100%;
  }

  .content:nth-child(2) {
    margin-top: 0rem;
  }

  .content > .imageSection {
    width: 100%;
    height: 298px;
  }

  .content > .textSection > h4 {
    font-size: 24px;
    line-height: 32px;
  }

  .content > .textSection > p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
}
