

.ant-steps-item .ant-steps-icon > span{
    background-color: transparent;
    color: #4F2915;
    border-color: #4F2915;
}

.ant-steps-item .ant-steps-icon > span > span{
    color: #4F2915;
    border-color: #4F2915;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon > span{
    background-color: #ff5500 !important;
    color: #fff;
    border: 0;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon > span > span{
    background-color: #ff5500 !important;
    color: #fff;
    border-color: #fff;
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
background-color: #4F2915;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
    background-color: #ff5500;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
    background-color: #4F2915;
}

.ant-steps-vertical{
    display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        margin: auto;
    }


    .ant-steps-vertical .ant-steps-item-icon > .ant-steps-icon {
        width: 300px !important;
        display: block;
    }
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    background-color: #ff5500;
    border-color: #ff5500;
}