.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  padding: 90px 1rem;
}

.container > h4 {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
}

.container > p {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 24px;
}

.buttonSection {
  width: 178px;
}

.buttonSection a {
  color: #fff;
  text-decoration: none;
}

.container > img {
  width: 614px;
  object-fit: cover;
  margin: 40px 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .container > p {
    font-size: 16px;
    line-height: 24px;
  }

  .container > img {
    width: 327px;
  }
}

@media screen and (max-width: 767px) {
  .container > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .container > p {
    font-size: 16px;
    line-height: 24px;
  }

  .container > img {
    width: 327px;
  }
}
