.container {
  padding: 4rem 0 4rem 100px;
  width: 70%;
}

.container > h4 {
  color: var(--Dark-Main, #20252f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 2rem;
}

.imagSection {
  display: flex;
  align-items: stretch;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image {
  height: 265px;
  position: relative;
}

.image:nth-child(1),
.image:nth-child(4) {
  width: calc(70% - 1.5rem);
  object-fit: cover;
}

.image:nth-child(2),
.image:nth-child(3) {
  width: 30%;
  object-fit: cover;
}

.image:nth-child(5),
.image:nth-child(6) {
  width: calc(30%);
  object-fit: cover;
}

.image:nth-child(7) {
  flex: 1;
}

.image > img {
  position: relative;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.viewMore {
  position: absolute;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  color: var(--White, #fff);
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.72px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  background: #000;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 2rem 1.5rem;
    width: 100%;
  }

  .image:nth-child(1),
  .image:nth-child(4) {
    width: calc(1000% - 1.5rem);
    object-fit: cover;
  }

  .image:nth-child(2),
  .image:nth-child(3) {
    width: 100%;
    object-fit: cover;
  }

  .image:nth-child(5),
  .image:nth-child(6),
  .image:nth-child(7) {
    width: calc(100% - (1.5rem / 3));
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 2rem 1.5rem;
    width: 100%;
  }

  .image:nth-child(1),
  .image:nth-child(4) {
    width: calc(1000% - 1.5rem);
    object-fit: cover;
  }

  .image:nth-child(2),
  .image:nth-child(3) {
    width: 100%;
    object-fit: cover;
  }

  .image:nth-child(5),
  .image:nth-child(6),
  .image:nth-child(7) {
    width: calc(100% - (1.5rem / 3));
    object-fit: cover;
  }
}
