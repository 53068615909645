.container {
  padding: 5rem 100px;
  overflow-x: hidden;
}

.container > h4 {
  color: var(--Dark-Main, #20252f);
  text-align: center;
  font-family: Lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.28px;
}

.dropdownSection {
  margin: 4rem auto;
  width: 70%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 4rem 1.5rem;
  }

  .container > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .dropdownSection {
    margin: 4rem auto;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 4rem 1.5rem;
  }

  .container > h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.96px;
  }

  .dropdownSection {
    margin: 4rem auto;
    width: 100%;
  }
}
